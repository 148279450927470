const contentSlider = {
  init (AOS) {

    // On Slider Init
    $(".c-content-slider").on('init', function(event, slick){
      AOS.refresh();
    });

    $(".c-content-slider").slick({
      lazyLoad: 'ondemand', // ondemand progressive anticipated
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      fade: true,
      autoplay: true,
      dots: true,
      cssEase: 'linear',
      nextArrow: '<button class="c-content-slider-nav-next"> <span class="icon-right"></span> </button>',
      prevArrow: '<button class="c-content-slider-nav-prev"> <span class="icon-left"></span> </button>',
      infinite: true
    });

	}
};

export default contentSlider;
